@import 'src/styles/spacer.scss';

.root {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  margin: $spacer-8;

  span {
    margin: $spacer-4;

  }
}
