//$background: #fafbfb;
$background: linear-gradient(90deg, rgba(250, 251, 254, 1) 0%, rgb(243, 243, 246) 45%, rgba(250, 251, 254, 1) 100%);
$backgroundCard: #fdfdfe;
$textColor: #828b95;

$errorColor: #f96767;
$errorBackgroundColor: #fce3e3;
$warningColor: #f8bb0d;
$warningBackgroundColor: #fcf2d2;
$successColor: #42d19c;
$successBackgroundColor: #dcf6ec;
$infoColor: #5499fb;

$orangeColor: #fab27b;
$blueColor: #5dc1de;


$bs-blue: #3688fc;
$bs-indigo: #727cf5;
$bs-purple: #6b5eae;
$bs-pink: #ff679b;
$bs-red: #fa6767;
$bs-orange: #ff9041;
$bs-yellow: #f9bc0d;
$bs-green: #42d29d;
$bs-teal: #02a8b5;
$bs-cyan: #44badc;
$bs-white: #fff;
$bs-gray: #98a6ad;
$bs-gray-dark: #343a40;
$bs-gray-100: #f1f3fa;
$bs-gray-200: #eef2f7;
$bs-gray-300: #dee2e6;
$bs-gray-400: #ced4da;
$bs-gray-500: #adb5bd;
$bs-gray-600: #98a6ad;
$bs-gray-700: #919ca7;
$bs-gray-800: #343a40;
$bs-gray-900: #474d56;
$bs-primary: #3688fc;
$bs-secondary: #919ca7;
$bs-success: #42d29d;
$bs-info: #44badc;
$bs-warning: #f9bc0d;
$bs-danger: #fa6767;
$bs-light: #eef2f7;
$bs-dark: #474d56;
$bs-primary-rgb: 54, 136, 252;
$bs-secondary-rgb: 145, 156, 167;
$bs-success-rgb: 66, 210, 157;
$bs-info-rgb: 68, 186, 220;
$bs-warning-rgb: 249, 188, 13;
$bs-danger-rgb: 250, 103, 103;
$bs-light-rgb: 238, 242, 247;
$bs-dark-rgb: 71, 77, 86;
$bs-white-rgb: 255, 255, 255;
$bs-black-rgb: 0, 0, 0;
$bs-body-rgb: 131, 140, 150;
$bs-font-sans-serif: "Nunito", sans-serif;
$bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
$bs-body-font-family: Nunito, sans-serif;
$bs-body-font-size: 0.9rem;
$bs-body-font-weight: 400;
$bs-body-line-height: 1.5;
$bs-body-color: #838c96;
$bs-body-bg: #fafbfe;