$spacer-1: 0.0625rem;
$spacer-2: (2 * $spacer-1);
$spacer-4: (4 * $spacer-1);
$spacer-8: (8 * $spacer-1);
$spacer-12: (12 * $spacer-1);
$spacer-16: (16 * $spacer-1);
$spacer-20: (20 * $spacer-1);
$spacer-24: (24 * $spacer-1);
$spacer-32: (32 * $spacer-1);
$spacer-64: (64 * $spacer-1);

$spacer-std-padding: $spacer-20;
$spacer-std-margin: $spacer-12;