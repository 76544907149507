@import 'src/styles/colors.scss';
@import 'src/styles/spacer.scss';
@import 'src/styles/shared.scss';


.indicators {
  @include row;
}

.indicatorCard {
  @include card;
}

.indicatorHead {
  @include cardHead;
}

.indicatorBody {
  @include row;
  //justify-content: space-evenly;
  justify-content: space-between;
}

.indicatorValue {
}

.indicatorChart {
}

.card {
  @include card;
}

.cardHead {
  @include cardHead;
}

.cardBody {
  @include cardBody;
}

.row {
  @include row;
}

.column {
  @include column;
}

.dashboard {
  margin: 0 auto;
  padding: 32px;
  max-width: 1280px;
}